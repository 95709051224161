// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import verticalMenuReducer from '../../navigation/vertical/reducer'
import verticalMenu from '@store/reducers/navigation'
import companyReducer from '../../navigation/vertical/reducer/companyReducer'
import loadingReducer from '../../navigation/vertical/reducer/LodingReducer'
import CustomerReducer from '../../navigation/vertical/reducer/CustomerReducer'
import ViewInvoiceReducer from '../../views/apps/invoice/email/store/reducer'
import ViewCustomerReducer from '../../views/apps/customer/viewCustomerDetail/store/reducer'
import ViewQuoteReducer from '../../views/apps/quotes/email/store/reducer'
import ViewPaymentReducer from '../../views/apps/paymentRecord/email/store/reducer'
import ViewCreditNoteReducer from '../../views/apps/credit-notes/email/store/reducer'
import ViewDebitNoteReducer from '../../views/apps/debit-notes/email/store/reducer'
import ViewBillReducer from '../../views/apps/bill/email/store/reducer'
import ViewPaymentMadeReducer from '../../views/apps/paymentMade/email/store/reducer'
import ViewExpenseReducer from '../../views/apps/expenses/email/store/reducer'
import apiReducers from './APIs/apiReducers'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  dataTables,
  verticalMenu,
  verticalMenuReducer,
  companyReducer,
  loadingReducer,
  CustomerReducer,
  ViewInvoiceReducer,
  ViewCustomerReducer,
  ViewQuoteReducer,
  ViewPaymentReducer,
  ViewCreditNoteReducer,
  ViewBillReducer,
  ViewPaymentMadeReducer,
  ViewDebitNoteReducer,
  ViewExpenseReducer,
  apiReducers
})

export default rootReducer
